import React from "react"
import Slider from 'react-slick'
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { Layout, Container, Seo, PostCard, RoundedBox, Glow } from "../../components"
import moment from "moment"

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import * as styles from './style.module.scss'

// PROVISORY
import HeaderImage from '../../assets/img/jimbob_carsonhoffman.png'
import PageContentImage1 from '../../assets/img/carson_playing.jpg'
import PageContentImage2 from '../../assets/img/jimbob_aftermatch.jpg'
import HowAboutImage1 from '../../assets/img/carson_playing2.png'
import HowAboutImage2 from '../../assets/img/jimbob_playing.png'
import WeBelieveImage from '../../assets/img/jimbob_01.png'

const AboutTemplate = ({ data, pageContext }) => {
  const { aboutPageFields, seoMetaFields } = data.about
	const { posts } = data
  const meta = seoMetaFields.wpFields.find(
    variant => variant.languageValue.slug === pageContext.lang
  )
  const copy = aboutPageFields.sectionCopy.find(
    variant => variant.languageValue.slug === pageContext.lang
  )
  const values = aboutPageFields.sectionValues.find(
    variant => variant.languageValue.slug === pageContext.lang
  )
  const { contentBlock1, contentBlock2, contentBlock3 } = copy

  const normalizedPosts = posts.edges.map(post => ({
    categories: post.node.categories.nodes.filter(
      cat => ![9, 10].includes(cat.databaseId)
    ),
    author: post.node.blogFields.wannaAuthor?.postAuthorFields,
    blog: post.node.blogFields?.blogFields?.find(
      variant =>
        variant.languageValue && variant.languageValue.slug === pageContext.lang
    ),
    featured: post.node.blogFields.isFeatured,
    date: moment(post.date).format("ll"),
    slug: post.node.slug,
    id: post.node.databaseId,
  }))

	const carouselSettings = {
		autoPlay: false,
		arrows: false,
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true
	}

	const postsCarouselSettings = {
		autoPlay: false,
		arrows: false,
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	}


  return (
    <Layout>
      <Seo title={meta.seoTitle} description={meta.seoDescription} />

			<RoundedBox
				className={styles.roundedBox}
				transform="rotate(26deg)"
				width="916px"
				height="996px"
				position={{
					top: "-180px",
					left: "-230px"
				}}
			/>

			<section className={styles.about}>
				<Container>
					<div className={styles.imageCard}>
						<Glow
							className={styles.glowYellow}
							color="var(--color-yellow)"
							width="200px"
							height="200px"
							position={{
								top: 0,
								left: "150px"
							}}
						/>
						<p>
							<span>WANNA BET</span>
						</p>
						<div clasName={styles.imageCard__image}>
							<img src={HeaderImage} alt={meta.seoTitle} />
							{/* <GatsbyImage
								image={copy.bannerImage.localFile.childImageSharp.gatsbyImageData}
								alt={meta.seoTitle}
							/> */}
						</div>
						<p>
							<span>Jim Bob Morris</span>
							<span>Carson Coffman</span>
						</p>

						<Glow
							className={styles.glowPurple}
							color="var(--color-secondary)"
							width="200px"
							height="200px"
							blur="80px"
							position={{
								bottom: 0,
								right: "150px"
							}}
						/>
					</div>

					<div className={`${styles.heroContent} flex align-center justify-center`}>
						<h1>{contentBlock1?.title}</h1>
						<div className={styles.text} dangerouslySetInnerHTML={{__html: contentBlock1?.copy}}></div>
					</div>

					<div className={styles.pageContent}>
						<div className={`${styles.pageContent__group} flex`}>
							<div className={styles.pageContent__text}>
								<div className={styles.card}>
									<p>{contentBlock2.title}</p>
								</div>
								<div className={styles.text} dangerouslySetInnerHTML={{__html: contentBlock2.copy}}></div>
							</div>
							<div className={styles.image}>
								<img src={PageContentImage1} alt={meta.seoTitle} />
								{/* <GatsbyImage
									image={contentBlock2.image1.localFile.childImageSharp.gatsbyImageData}
									alt={meta.seoTitle}
								/> */}
							</div>
						</div>
						<div className={styles.pageContent__image}>
							<img src={PageContentImage2} alt={meta.seoTitle} />
							{/* <GatsbyImage
								image={contentBlock2.image2.localFile.childImageSharp.gatsbyImageData}
								alt={meta.seoTitle}
							/> */}
						</div>
					</div>

					<div className={styles.howAbout}>
						<div className={`${styles.howAbout__content} flex`}>
							<h1>{contentBlock3.title}</h1>
							<div className={styles.text} dangerouslySetInnerHTML={{__html: contentBlock3.copy}}></div>
						</div>
						<div className={styles.howAbout__images}>
							<div className={styles.image}>
								<img src={HowAboutImage1} alt={meta.seoTitle} />
								{/* <GatsbyImage
                  image={contentBlock3.image1.localFile.childImageSharp.gatsbyImageData}
                  alt={meta.seoTitle}
								/> */}
							</div>
							<div className={styles.image}>
								<img src={HowAboutImage2} alt={meta.seoTitle} />
								{/* <GatsbyImage
                  image={contentBlock3.image2.localFile.childImageSharp.gatsbyImageData}
                  alt={meta.seoTitle}
								/> */}
							</div>
						</div>
					</div>

					<div className={`${styles.weBelieve} flex`}>
						<div className={styles.content}>
							<h1>{values.valuesBlocks.title}</h1>
							<div dangerouslySetInnerHTML={{__html: values.valuesBlocks.copy}}></div>
						</div>
						<div className={styles.slider}>
							<Slider {...carouselSettings}>
								<div className={styles.slide}>
									<img src={WeBelieveImage} alt={meta.seoTitle} />
								</div>
								<div className={styles.slide}>
									<img src={WeBelieveImage} alt={meta.seoTitle} />
								</div>
								<div className={styles.slide}>
									<img src={WeBelieveImage} alt={meta.seoTitle} />
								</div>
								<div className={styles.slide}>
									<img src={WeBelieveImage} alt={meta.seoTitle} />
								</div>
							</Slider>
						</div>
					</div>

					{
						normalizedPosts.length > 0 && pageContext.lang.includes("pt") &&
						<div className={styles.normalizedPosts}>
							<p className={styles.normalizedPosts__title}>
								{(pageContext.lang === "en_us" ? "WANNA BLOG" : "BLOG DO WANNA")}
							</p>

							<hr />

							<Slider {...postsCarouselSettings}>
								{
									normalizedPosts.map(post =>
										post && post.blog &&
										<PostCard className={styles.slide} post={post} key={post.id} pageContext={pageContext} />
									)
								}
							</Slider>
						</div>
					}
				</Container>
			</section>
      {/* <Theme.Padding>
        <Theme.Wrap size="wide">
          <Theme.BannerImg>
            <GatsbyImage
              image={copy.bannerImage.localFile.childImageSharp.gatsbyImageData}
              alt={meta.seoTitle} />
          </Theme.BannerImg>
          <Theme.BannerText>
            <span>Jim Bob Morris</span>
            <span>Carson Coffman</span>
          </Theme.BannerText>
        </Theme.Wrap>

			<aside>
				<Theme.Wrap>
					<Theme.RelatedPostsHeading>
						{pageContext.lang === "pt_br"
							? "Últimas notícias do Wanna"
							: "Wanna's latest news"}
					</Theme.RelatedPostsHeading>
					<Theme.Posts>
						{normalizedPosts.length > 0
							? normalizedPosts.map(post =>
									post && post.blog ? (
										<PostCard post={post} key={post.id} pageContext={pageContext} />
									) : null
								)
							: pageContext.lang === "pt_br"
							? "0 Resultados..."
							: "0 Results..."}
					</Theme.Posts>

				</Theme.Wrap>
			</aside> */}
    </Layout>
  )
}

export const Query = graphql`query AboutTemplate {
  about: wpPage(slug: {eq: "about-wanna"}) {
    id
    seoMetaFields {
      wpFields {
        languageValue {
          slug
          name
        }
        seoTitle
        seoDescription
      }
    }
    aboutPageFields {
      sectionCopy {
        languageValue {
          slug
          name
        }
        bannerImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        contentBlock1 {
          title
          copy
        }
        contentBlock2 {
          title
          copy
          image1 {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
          image2 {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
        }
        contentBlock3 {
          title
          copy
          image1 {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
          image2 {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      sectionValues {
        languageValue {
          slug
          name
        }
        valuesBlocks {
          title
          copy
          images {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: NONE
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
    }
  }
	posts: allWpPost(
    limit: 3,
    filter: {
      status: {eq: "publish"},
      blogFields: {
        blogFields: {
          elemMatch: {
            blogTitle: {ne: null},
            blogImage: {
              localFile: {
                childImageSharp: {
                  gatsbyImageData: {ne: "null"}
                }
              }
            },
            excerpt: {ne: null},
            languageValue: {
              slug: {ne: null}
            },
            readingTime: {ne: null}
          }
        }
      }
    }
  ) {
		edges {
			node {
				categories {
					nodes {
						databaseId
						slug
						name
						parentDatabaseId
					}
				}
				date
				slug
				databaseId
				blogFields {
					isFeatured
					wannaAuthor {
						... on WpAuthor {
							postAuthorFields {
								image {
									localFile {
										childImageSharp {
											gatsbyImageData(width: 80, layout: FIXED)
										}
									}
								}
								link
								name
							}
						}
					}
					blogFields {
						blogTitle
						blogImage {
							localFile {
								childImageSharp {
									gatsbyImageData(width: 600, layout: FIXED)
								}
							}
						}
						excerpt
						languageValue {
							slug
						}
						readingTime
					}
				}
			}
		}
	}
}
`

export default AboutTemplate
